.slideshow-container {
    width: 100%;
    position: relative;
    margin: auto;
    height:250px;
}
.imageStyle{
width: 100%;
height: 100%;
}
.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 5.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    border:none;
    background-color:transparent
}
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

.displayNone{
    display:none;
}
.displayBlock {
    display: block;
}

.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

.text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 13px;
    width: 100%;
    text-align: center;
    float: left;
}

/* Fading animation */
.fade {
    width: 100%;
    height: 100%;
    animation-name: fade;
    animation-duration: 1.5s;
}
@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}