* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Georgia,ui-serif,Cambria,Times New Roman,Times,serif;
  color: #262626;
}

#chartmain {
  height: 15em;
  width: 100%;
}

.code {
  background-color: #e6e6e6;
  font-family:  Consolas, serif;
  font-style: italic;
  padding: 10px;
}

.t1 {
    background-color: steelblue;
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 25px;
    margin-top: 25px;
    padding: 2px 15px 5px 2px;
}

.userguide {
  align: justify;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 25px;
  padding: 5px 15px 15px 20px;
  font-size: inherit;
}

.userguide button {
  background: steelblue;
  font-size: 100%;
  padding: 10px;
  margin: 1px;
  color: white;
  border: none;
  cursor: pointer;
}

.userguide button:hover {
  background: green;
}

.userguide li {
  margin-left: 20px;
}

ul { 
  padding: 10px;
}

li { 
  padding-bottom: 10px;
}

.borderimg { 
  border: 4px solid black;
  display: block;
  float: none; 
  margin: 20px 0 20px 0;
  width: 100%;
}

.noborderimg { 
  display: block;
  float: none; 
  margin: 20px 0 20px 0;
}

h1 {
  font-size: 2em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h3 {
  padding-bottom: 10px;
}

tab1 {
  padding-left: 10px;
}

tab2 {
  padding: 0 0 0 40px;
}

tab3 {
  padding: 0 0 0 60px;
}

tab4 {
  padding: 0 0 0 80px;
}

tab5 {
  padding: 0 0 0 100px;
}

tab6 {
  padding: 0 0 0 120px;
}

tab7 {
  padding: 0 0 0 140px;
}

tab8 {
  padding: 0 0 0 160px;
}

.round {
  border: lightgrey 2px solid;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  
  text-align: center;
  display: inline-block;
  background-color: transparent;
  font-size: 85%;
}

.text {
  padding: 5px;
  margin: 5px;
  white-space: pre; /*important, this shows \n as line break*/
  text-align: left;
}

.red {
  color: #FF0040;
}

.green {
  color: #21610B;
}

.normal {
}

a:-webkit-any-link {
  color: #003399;
  cursor: pointer;
}

a:link {
  color: #045FB4;
  text-decoration: none;
}

a:visited {
  color: #045FB4;
  text-decoration: none;
}

a:hover {
  color: red;
  text-decoration: none;
}

span {
 cursor:pointer;
 color:#045FB4;
}

span:hover {
 cursor:pointer;
 color:red;
}

body {
  height: 100vh;
  font-size: 19px;
}

header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
  background-color: #00bfff;
}

form {
  font-size: 80%;
  line-height: 25px;
}

button {
  font-size: 100%;
  padding: 4px;
  margin: 1px;
}

input:not([type="file" i], [type="image" i], [type="checkbox" i], [type="radio" i]) 
{
   margin-bottom: 10px;
   font-size: inherit;
   padding: 5px;
   width:150px;
   color: #2E2E2E;
}

input[type="text"] 
{ 
   margin-bottom: 10px;
   font-size: inherit;
   padding: 5px;
   width:150px;
}

input.error
{
    border: 3px solid #e68a00;
}

textarea:not([type="file" i], [type="image" i], [type="checkbox" i], [type="radio" i]) 
{
   margin-bottom: 10px;
   font-size: inherit;
   padding: 5px;
   color: #2E2E2E;
   width: 600px;
}

textarea[type="text"] 
{ 
   margin-bottom: 10px;
   font-size: inherit;
   padding: 5px;
   width: 600px;
}

input[type="password"] 
{ 
   margin-bottom: 10px;
   font-size: inherit;
   padding: 5px;
   width:150px;
}

input[type="date"] 
{ 
   margin-bottom: 10px;
   font-size: inherit;
   padding: 5px;
   width:150px;
   color: gray;   
}

select 
{ 
   margin-bottom: 10px;
   font-size: inherit;
   padding: 5px;
   width:150px;
   color: gray;   
   text-decoration-color: gray;
}

.invalid {
  border:1px solid #ff0000
}

.normal {
}

.center {
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 110%;
}

.inlinecenter {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.nav-area {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 7px 15px;
  /*background: linear-gradient(#0073e6, #80bfff);*/
  background: linear-gradient(#0073e6, #00bfff);
  color: black;
}

.signup {
  display: flex;
  text-align: right;
  margin: 0 auto;
  padding: 7px 15px ;
  background: linear-gradient(#80bfff, #cce6ff);
  font-size: 72%;
  float: right;
  margin-right: 10px;
}

.signup a {
  text-decoration: none;
}

.small-text {
  font-size: 80%;
}

.page-index {
  font-size: 80%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.page-index a:hover {
  font-size: 120%;
}

.page-index a:visited {
  font-weight: bolder;
  color: red;
}

.medium-text {
  font-size: 90%;
}

.logo {
  text-decoration: none;
  color: inherit;
  margin-right: 5px;
  vertical-align: middle;
  align-items: left;
  text-align: left;
  display: flex;  
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  position: relative;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  font-weight: normal;
  color: #333333;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a, .menu-items button {
  text-align: left;
  padding: 0.3rem 0.7rem;
  font-size: 95%;
  width: fit-content;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #f2f2f2;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: 5%;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.1),
    0 6px 6px -2px #00bfff;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.4rem;
  display: none;
  text-align: justify;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* content */

.content {
  width: 75%;
  margin: 0 auto;
  height: 100%;
  padding: 30px 20px 20px 20px;
  float: left;
  text-align: justify;
  line-height: 38px;
}

#all-content {
  /*overflow: scroll;*/
  background-color: #f2f2f2;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a:link {
  color: #045FB4;
  text-decoration: none;
}

.content a:visited {
  color: #007399;
  text-decoration: none;
}

.content a:hover {
  color: red;
  text-decoration: none;
}


/* Right hand sidebar */
#rhsb {
  width:25%;
  padding: 30px 20px 20px 20px;
  float: left;
  border-left: solid 1px lightgray;
  background-color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70%;
}

#main-content {
  display: flex;
}


#footer {
  width:100%;
  padding: 40px 10px;
  float: left;
  color: white;
  text-align: center;
  background-color: #040720;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70%;
}

.footspan {
  color: yellow;
  font-size: 140%;
  text-decoration: bold;
}

.bold {
  font-weight: bold;
}

#top-slider {
  background-color: white;
}

#error {
  color: #FF0040;
  border: 1px solid #F7819F;
  padding: 5px;
  background-color: #F8E0E6;
}

#success {
  color: #21610B;
  border: 1px solid #04B45F;
  padding: 5px;
  background-color: #CEF6CE;
}


.alert {
  padding: 5px;
  margin: 5px;
  white-space: pre; /*important, this shows \n as line break*/
  text-align: center;
}

.alert.error {
  color: #FF0040;
  border: 1px solid #F7819F;
  background-color: #F8E0E6;
}

.alert.success {
  color: #21610B;
  border: 1px solid #04B45F;
  background-color: #CEF6CE;
}

.statstbl {
  padding-top: 7px;
  width: 100%;
  line-height: 30px;
  table-layout: fixed;
}

.statstbl caption {
  color: darkblue;
  font-weight: bold;
  font-size: 120%;
  padding-top: 20px;
}

.statstbl td, .statstbl th {
  border: 0 none #fff;
  padding: 0 10px 0 10px;
  width: 100%;
}

.statstbl tr:nth-child(even){background-color: #E5E7E9;}
.statstbl tr:nth-child(odd){background-color: #D5D8DC;}

.statstbl tr:hover {background-color: #BBDEFB;}

.statstbl th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  /*background-color: #90CAF9;*/
  background: linear-gradient(#3399ff, #99ccff);
  color: black;
  font-size: 90%;
  font-style: normal;
}

.resulttbl {
  width: 100%;
}


 .resulttbl th {
  padding-right: 5px;
  text-align: left;
  background-color: #c4e3ed;
}

.resulttbl td {
  border-bottom: 1px dotted #a9a9a9;
  padding-right: 5px;
  text-align: left;
}

.resulttbl tr:nth-child(even){background-color: transparent;}
.resulttbl tr:nth-child(odd){background-color: transparent;}

#tbl {
  border-collapse: collapse;
  width: 100%;
}

#tbl td, #tbl th {
  border: 1px solid #ddd;
  padding: 8px;
}

#tbl tr:nth-child(even){background-color: #f2f2f2;}

#tbl tr:hover {background-color: #ddd;}

#tbl th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background: linear-gradient(#0073e6, #00bfff);
  color: white;
  font-size: 90%;
  font-style: normal;
}


.tealtbl {
  margin: 10px 0 1px 0;
  border-collapse: collapse;
  width: 100%;
}

.tealtbl th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background: linear-gradient(#0073e6, #00bfff);
  color: white;
  font-size: 90%;
  font-style: normal;
}

.tealtbl td, .tealtbl th {
  border: 1px solid #ddd;
  padding: 8px;
}

.tealtbl tr:nth-child(even) {
    /*background-color: #c4e3ed;*/
    background-color: #b3e6ff;
}

.tealtbl tr:nth-child(odd) {
    /*background-color: #76bdd5;*/
    background-color: #66ccff;
}

.tealtbl a {
  color: blue;
  text-decoration: none;
}

.tealtbl a:hover {
  color: red;
}

::-ms-input-placeholder { /* Edge 12-18 */
  color: red;
}

::placeholder {
  font-size: inherit;
}

.tstbl th {
  font-weight: normal;
}

.tstbl tbody tr td:first-child {
  width: 3em;
}

.tstbl tbody tr:hover {
  /*font-style: italic;*/
}

.txupper {
  text-align: left;
  background-color: #66ccff;
  padding: 0px 15px 15px 15px;
}

.txthinner {
  margin: 5px 0 0 0;
  text-align: center;
  padding: 10px;
}

.minibuttons {
  border-radius: 50px;
  border: 2px solid #ffffff;
  width: 12px;
  height: 12px;
  cursor: zoom-out;
}

.miniimgbtns {
  border: 1px solid white;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 2px 2px;
  cursor: pointer;
  border-radius: 50%;
}

.progressbar {
  width: 84%;
  height: 20px;
  margin: 12px;
  border-radius: 25px;
  /*background: url(./images/paper.jpeg);
  background-position: left top;
  background-repeat: repeat;*/
}

.rc-progress-line-trail {
  stroke: #CEF6CE;
  strokeColor: #009900;
}

.cursor-pointer {cursor: pointer;}
.cursor-crosshair {cursor: crosshair;}
.cursor-eresize {cursor: e-resize;}
.cursor-move {cursor: move;}

